import localforage from "localforage";

const config = {
  baseUrl: "https://apis.resicap2021.cortexcraft.com",
  loginUrl: "https://resiaims-2.com/",
  netlifyUrl: "https://resiaims-2.com/",
  //baseUrl: "https://hoa-test.cortexcraft.com",
  // 'baseUrl': 'https://hoa-prod.cortexcraft.com',
  // loginUrl: "https://hoa-test-cortexcraft.netlify.app/",
  // netlifyUrl: "https://hoa-test-cortexcraft.netlify.app/",
  accessToken: "access-token",
  success: "SUCCESS",
  localforage: localforage.createInstance({
    name: "hoa",
  }),
  US_DATE_FORMAT: "MM/DD/YYYY",
  US_DATE_TIME_FORMAT: "MM/DD/YYYY HH:mm:ss",
  API_DATE_FORMAT: "YYYY-MM-DD",
  debounceTimer: 500,
  actionType: "actionTypes",
  listofTabs: [
    "Dashboard",
    "Create Analyst",
    "Area Analysts",
    "Property Upload",
    "Property Search",
    "Property Manage",
  ],
  userId: "",
  userRole: "",
  PropertyMap: {
    clientid: "Property ID",
    status: "Status",
    mortgage_amount: "Mortgage Amount",
    street_address: "Street Address",
    city: "City",
    state: "State",
    zip: "Zip",
    county: "County",
    entity_name: "Entity Name",
    vested_name: "Vested Name",
  },
  accessCodesmap: {
    access_for: "access_for",
    access_to: "access_to",
    access_contact_name: "access_contact_name",
    access_contact_phone: "access_contact_phone",
    access_contact_email: "access_contact_email",
    access_control: "access_control",
    access_control_cost: "access_control_cost",
    access_description: "access_description",
    code: "code",
  },
  hoaMap: {
    name: " HOA Name",
    contact_email: "Email",
    contact_phone: "Phone Number",
  },
  // legalInfoMap: {
  //   id: "Legal Information ID",
  //   critical_rental_restrictions: "Is there a Critical Rental Restrictions ?",
  //   at_lease_cap: "Is there a Lease Cap ?",
  //   owner_reside_before_lease:
  //     "Must the owner reside in the unit before leasing?",
  //   own_before_leasing_min:
  //     "Must the owner own the unit for a minimum number of months before leasing ?",
  //   own_before_leasing_min_months: "Number of months unit must be owned ?",
  //   min_age_for_leasing: "Is there a minimum age for leasing ?",
  //   min_age_for_leasing_months: "Minimum age for leasing ?",
  //   lease_max_times_per_year:
  //     "Is there a maximum number of times a unit can be leased in a 12-month period ?",
  //   lease_max_times_per_year_value:
  //     "Maximum number of times unit may be leased in a 12-month period ?",
  //   lease_min: "Must the unit be leased for a minimum number of months ?",
  //   lease_min_months: "Minimun number of months for leasing ?",
  //   lease_max: "Is there a maximum number of months the unit may be leased?",
  //   lease_max_months: "Maximum number of months for leasing ?",
  //   lease_license_required: "Is a lease license required ?",
  //   lease_approval_required: "Is lease approval required by the association ?",
  //   tenant_application_required: "Is a tenant application required ?",
  //   association_move_in_checklist:
  //     "Is there an association move-in checklist ?",
  // },
  // legalQuestionsMap: {
  //   //critical_rental_restrictions: 'boolean',
  //   at_lease_cap: "boolean",
  //   owner_reside_before_lease: "boolean",
  //   own_before_leasing_min: "boolean",
  //   own_before_leasing_min_months: "string",
  //   min_age_for_leasing: "boolean",
  //   min_age_for_leasing_months: "string",
  //   lease_max_times_per_year: "boolean",
  //   lease_max_times_per_year_value: "string",
  //   lease_min: "boolean",
  //   lease_min_months: "string",
  //   lease_max: "boolean",
  //   lease_max_months: "string",
  //   lease_license_required: "boolean",
  //   lease_approval_required: "boolean",
  //   tenant_application_required: "boolean",
  //   association_move_in_checklist: "boolean",
  // },
  booleanMap: {
    Yes: true,
    No: false,
  },
  paymentResponsiblities: {
    management: "Management",
    owner: "Owner",
    tenant: "Tenant",
  },
  responsibility: {
    owner: "Owner",
    tenant: "Tenant",
  },
  chargeTypes: {
    assessment: "Assessment",
    fine: "Fine",
    legal: "Legal",
    estoppel: "eStoppel",
    administrative: "Administrative",
    special_assessment: "Special Assessment",
    documents: "Documents",
    others: "Others",
  },
  periodicity: {
    "One-Time": "one-time",
    Daily: "day",
    Weekly: "week",
    "Bi-Weekly": "bi-week",
    Monthly: "month",
    Quarterly: "quarter",
    "Semi-Annually": "semi-annual",
    Annually: "annual",
    Voluntary: "Voluntary",
  },
  periodicityDummy: {
    day: "Daily",
    week: "Weekly",
    "bi-week": "Bi-Weekly",
    month: "Monthly",
    quarter: "Quarterly",
    "semi-annual": "Semi-Annually",
    annual: "Annually",
    Voluntary: "Voluntary",
  },

  attorneyTypes: {
    ResicapAttorney: "Resicap",
    HoaAttorney: "HOA",
  },
  actionTypes: {
    AccountReview: "Account Review",
    AssociationVerification: "Association Verification",
    DocumentInfoReport: "Document Info Report",
    Pir: "PIR",
  },
  hoa_mc_access: [
    "admin",
    "manager",
    "senior_analyst",
    "violation_analyst",
    "leasing_sales_analyst",
    "payment_analyst",
    "coordinator",
    "controller",
    "association_analyst",
    "onboarding_analyst",
    "executive",
  ],
  readOnlyTable: ["general_user", "executive", "vendor"],
  createAction: [
    "admin",
    "manager",
    "senior_analyst",
    "violation_analyst",
    "leasing_sales_analyst",
    "payment_analyst",
    "coordinator",
    "controller",
    "association_analyst",
    "onboarding_analyst",
  ],
  onlyAdminWrite: ["admin"],
  showHoa: ["admin", "manager", "senior_analyst", "onboarding_analyst"],
  propertyUpload: ["admin", "manager"],
  paymentRead: [
    "violation_analyst",
    "leasing_sales_analyst",
    "executive",
    "vendor",
  ],
  paymentWrite: [
    "payment_analyst",
    "senior_analyst",
    "onboarding_analyst",
    "coordinator",
    "controller",
    "admin",
    "manager",
  ],
  paymentHide: ["association_analyst", "general_user"],
  chargesCreateOnly: ["violation_analyst", "leasing_sales_analyst", "admin"],
  chargesWrite: [
    "senior_analyst",
    "payment_analyst",
    "coordinator",
    "controller",
    "manager",
  ],
  chargesRead: ["executive", "vendor"],
  chargeApprove: ["senior_analyst", "manager"],
  chargesHide: ["onboarding_analyst", "executive", "vendor"],
  ViolationHide: ["association_analyst", "general_user"],
  ViolationWrite: [
    "senior_analyst",
    "violation_analyst",
    "leasing_sales_analyst",
    "payment_analyst",
    "coordinator",
    "controller",
    "admin",
    "manager",
  ],
  ViolationRead: ["onboarding_analyst", "executive", "vendor"],
  LeaseInfoRead: ["executive", "vendor", "general_user"],
  LeaseInfoWrite: [
    "senior_analyst",
    "violation_analyst",
    "payment_analyst",
    "leasing_sales_analyst",
    "admin",
    "manager",
    "coordinator",
    "controller",
    "onboarding_analyst",
  ],
  LeaseHide: ["association_analyst"],
  LiensRead: [
    "violation_analyst",
    "leasing_sales_analyst",
    "payment_analyst",
    "onboarding_analyst",
    "coordinator",
    "controller",
    "executive",
    "vendor",
  ],
  LiensWrite: ["senior_analyst", "admin", "manager"],
  LiensHide: ["association_analyst", "general_user"],
  payeeTypes: {
    Hoa: "HOA",
    ManagementCompany: "Management Company",
    Attorney: "Attorney",
  },
  generalInfoRead: ["executive", "vendor", "general_user"],
  FrequencyMap: {
    monthly: "Monthly",
    quarterly: "Quarterly",
    "semi-annually": "Semi Annually",
    annually: "Annually",
  },
  documentNames: [
    "CC&Rs",
    "By Laws",
    "Amendments",
    "ARC Form",
    "Move-in Checklist Form",
    "Tenant Registration",
    "Parking Permit Form",
    "Pool Registration Form",
    "W9",
    "Other",
  ],
  hoaDocumentNames: [
    "CC&Rs",
    "By Laws",
    "Amendments",
    "Move-in Checklist Form",
    "Tenant Registration",
    "Parking Permit Form",
    "Pool Registration Form",
    "W9",
    "Other",
  ],
  HoaPocTitles: [
    "President",
    "Vice-President",
    "Secretary",
    "Treasurer",
    "Other",
  ],
  McPocTitles: {
    "property-manager": "Property Manager",
    "assisstant-property-manager": "Assisstant Property Manager",
    accounting: "Accounting",
    other: "Other",
  },
  charge_type: [
    "Administrative",
    "Amendment",
    "Application Processing Fee",
    "Assessment",
    "Assessment FY18",
    "Assessment FY19",
    "Assessment FY20",
    "Assessment FY21",
    "Assessment FY22",
    "Assessment FY23",
    "Assessment FY24",
    "Assessment FY25",
    "Amenity Access",
    "Common Charge",
    "Documents",
    "eStoppel",
    "Fine",
    "Gate Access",
    "Initiation Fee",
    "Inspection Fee",
    "Interest",
    "Late Fee",
    "Legal",
    "Maintenance Fee",
    "Other",
    "Rental Fee",
    "Registration",
    "Rush Fee",
    "SA1 - Special Assessment 1",
    "SA2 - Special Assessment 2",
    "SA3 - Special Assessment 3",
    "Security Deposit",
    "Special Assessment",
    "Tenant Application",
    "Tenant Background Check",
    "Tenant Registration",
    "UC - Utility (Cable/WiFi)",
    "UE - Utility (Electric)",
    "UG - Utility (Gas)",
    "UT - Utility (Trash)",
    "UW - Utility (Water)",
    "Violation Fine",
    "Violation Handling Fee",
    "Violation Postage Fee",
  ],

  Months_Array: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  commentType: {
    Approved: "approval",
    Rejected: "rejection",
  },
  reportsMap: {
    accessCodes: "Access Codes",
    actions: "Actions",
    aims_hb_report: "AIMS to HBAM Upload",
    amneties: "Amenities",
    approved_payments: "Approved Payments",
    rp_mpal_report: "Assessment Description",
    attorneys: "Attorneys",
    checksent_report: "Check Sent",
    violation_code_and_permit: "Code And Permit",
    documents: "Documents",
    hoas: "Hoas",
    inspectionCategory: "Inspection Categories",
    // leasingInfo: "Leasing Information",
    leasing_info_report: "Leasing Information",
    managementCompany: "Management Companies",
    mpal_report: "MPAL Report",
    municipal_orders: "Municipal Orders",
    municipal_verification: "Municipality Verification",
    municipality_registration_overview: "Municipality Registration Overview",
    municipality_registration_tracker: "Municipality Registration Tracker",
    aims_payment_report: "New Payment",
    notes: "Notes",
    payments: "Payments",
    pc_aggregated_list: "PC Aggregated Report",
    properties: "Properties",
    property_pre_checklist: "Property Pre Checklist",
    tenantMoveIn: "Tenant Move-in",
    title_orders: "Title Orders",
    utilities: "Utilities",
    violations: "Violations",
    municipal_actions_tab_list: "Municipal Actions Tab List",
  },
  leaseInfoMap: {
    own_before_leasing_min: "own_before_leasing_min_months",
    min_age_for_leasing: "min_age_for_leasing_months",
    lease_max_times_per_year: "lease_max_times_per_year_value",
    lease_min: "lease_min_months",
  },
  addressTypes: {
    physical: "Physical Address",
    mailing: "Local Mailing Address",
    payment: "Payment Mailing Address",
  },
  paymentHoaAction: ["FyAssessment", "AccountReview", "GeneralPayment"],
  payment_settlementStatus: {
    outstanding: "Outstanding",
    cleared: "Cleared",
    stop_payment_reissued: "Stop Payment - Reissued",
    stop_payment_no_reissue: "Stop Payment - No Reissue",
    void_reissued: "Void - Reissued",
    void_no_reissue: "Void - No Reissue",
  },
  payment_reason: {
    sold: "Sold",
    wrong_payee: "Wrong Payee",
    wrong_amount: "Wrong Amount",
    "90_day_void": "90 Day Void",
    other: "Other",
  },
  asv_poc: [
    "point_of_contact_1",
    "point_of_contact_2",
    "point_of_contact_3",
    "email1",
    "email2",
    "email3",
    "phone1",
    "phone2",
    "phone3",
    "ext1",
    "ext2",
    "ext3",
  ],
  payment_document_category: ["Invoice", "Document", "Misc"],
  payment_document_name_invoice: [
    "Assessment",
    "Credit",
    "Estoppel",
    "General Ledger",
    "Ground Rent",
    "Leasing Fee",
    "Legal",
    "Special Assessment",
    "Violation Fines",
  ],
  payment_document_name_document: ["Budget - Approved", "Budget - Proposed"],
  amenitiesMap: {
    community_pool: "Is there a Community Pool?",
    cp_access_controlled: "Is Pool Access Controlled?",
    community_fitness_center: "Is there a Community Fitness Center? ",
    cfc_access_controlled: "Is Fitness Center Access Controlled?",
    community_tennis_court: "Is there a Community Tennis Court?",
    tc_access_controlled: "Is Tennis Court Access Controlled?",
    community_club_house: "Is there a Community Club House? ",
    cch_access_controlled: "Is Community Club House Access Controlled?",
    community_golf_course: "Is there a Community Golf Course? ",
    gc_covered_association:
      "Is the golf course covered by the association assessment?",
    parking_available_onsite: "Is Parking available onsite?",
    parking_assigned: "Is Parking assigned? ",
    parking_fee: "Parking Fee",
    vehicle_registration: "Is Vehicle Registration Required? ",
    upload_doc: "Upload Tenant Application",
    assigned_mailbox: "Is there an assigned mailbox?",
    mailbox_access: "Who needs to be contacted to get access to the mailbox? ",
    "documents_attributes[][category]": "",
    "documents_attributes[][name]": "",
    "documents_attributes[][memo_notes]": "",
    "documents_attributes[][file]": "",
  },
  amenitiesObj: {
    community_pool: "cp_access_controlled",
    community_fitness_center: "cfc_access_controlled",
    community_tennis_court: "tc_access_controlled",
    community_club_house: "cch_access_controlled",
    community_golf_course: "gc_covered_association",
    parking_available_onsite: "parking_assigned",
    // parking_assigned: "parking_fee",
    assigned_mailbox: "mailbox_access",
    vehicle_registration: "upload_doc",
  },
  leasingMap: {
    leasing_info_applicable: "Is Property Leasing Permitted?",
    at_lease_cap: "Is there a Lease Cap?",
    owner_reside_before_lease:
      "Must the owner reside in the unit before leasing?",
    own_before_leasing_min:
      "Must the owner own the unit for a minimum number of months before leasing?",
    senior_living_comunity: "Is this an adult living community?",
    lease_max_times_per_year:
      "Is there a maximum number of times a unit can be leased in a 12-month period?",
    lease_max: "Is there a maximum number of months the unit may be leased?",
    lease_license_required: "Is a lease license required?",
    rental_fee_required: "Is there a rental fee?",
    lease_approval_required: "Is lease approval required by the association? ",
    association_app_fee_required: "Is Association Application Fee Required?",
    copy_of_lease_required:
      "Does a copy of the lease need to be provided to the association?",
    tenant_approval_required: "Is a tenant approval required?",
    move_in_fee_required: "Is there a move-in fee?",
    pet_allowed: "Are there any Pet Restrictions?",
    bg_check_required: "Background Check Required?",
    tenant_application_required: "Is a tenant application required?",
    association_move_in_checklist:
      "Is there an association move-in checklist? ",
    check_in_documents: "",
    critical_rental_restrictions: false,
    lease_copy_submitted_at: "Date a copy of the lease was provided",
    lease_license_address: "Township,City or Municipality name?",
    lease_max_months: "",
    lease_max_times_per_year_value:
      "Number of times a unit may be leased in a year?",
    lease_min: false,
    lease_min_months: null,
    max_time_for_tenant_approval:
      "Maximum length of time for board to approve a tenant?",
    min_age_for_leasing: false,
    min_age_for_leasing_months: null,
    move_in_fee_amount: "How much is the move-in fee?",
    pet_restrictions: "Pet Restrictions",
    bg_check_responsiblilty: "Background Check Responsibility",
    association_app_fee: "Association Application Fee",
    own_before_leasing_min_months:
      "Number of months a unit must be owned before leasing",
    rental_fee_amount: "How much is the rental fee?",
    status: null,
    tenant_application_required: "Is a tenant application required?",
    tenant_documents: "",
    "documents_attributes[][category]": "",
    "documents_attributes[][name]": "",
    "documents_attributes[][memo_notes]": "",
    "documents_attributes[][file]": "",
  },
  leasingSectionOneObj: {
    leasing_info_applicable: "",
  },
  leasingSectionTwoObj: {
    at_lease_cap: "",
    owner_reside_before_lease: "",
    own_before_leasing_min: "own_before_leasing_min_months",
    senior_living_comunity: "",
    lease_max_times_per_year: "lease_max_times_per_year_value",
    lease_max: "",
  },
  leasingSectionThreeObj: {
    lease_license_required: "lease_license_address",
    rental_fee_required: "rental_fee_amount",
    lease_approval_required: "",
    association_app_fee_required: "association_app_fee",
    copy_of_lease_required: "lease_copy_submitted_at",
    tenant_approval_required: "max_time_for_tenant_approval",
    pet_allowed: "pet_restrictions",
    bg_check_required: "bg_check_responsiblilty",
    tenant_application_required: "tenant_documents",
    association_move_in_checklist: "check_in_documents",
    move_in_fee_required: "move_in_fee_amount",
  },
  property_document_category: [
    "Accounting",
    "Association Correspondence",
    "Deed",
    "Legal",
    "Lien",
    "Municipal",
    "Property Management",
    "Violation",
  ],
  association_document_category: [
    "Architectural",
    "Governance",
    "Leasing",
    "Newsletter / Notice",
    "Rules and Policy",
    "Financial / Budget",
  ],
  hoa_association_document_category: [
    "Governance",
    "Leasing",
    "Newsletter / Notice",
    "Rules and Policy",
    "Financial / Budget",
  ],
  association_correspondence_document_name: ["Email", "Letter"],
  municipal_document_name: [
    "Certificate of Occupancy (New Construction)",
    "Certificate of Occupancy (Registrations)",
  ],
  property_management_document_name: ["Lease"],
  accounting_document_name: [
    "Assessment Coupon",
    "Collection Notice",
    "Estoppel",
    "Ground Rent",
    "Ledger",
    "Statement of Account",
    "Website Registration",
  ],
  legal_document_name: [
    //"Certificate of Title",
    "Deed",
    "HOA Letter",
    "HUD",
    "Other",
  ],
  violation_document_name: [
    "Courtesy Notice",
    "Client Notice",
    "First Notice",
    "Second Notice",
    "Third Notice",
    "Fourth Notice",
    "Fifth Notice",
    "Sixth Notice",
    "Seventh Notice",
    "Eigth Notice",
    "Ninth Notice",
    "Final Notice",
    "Intet to Fine",
    "Neighbor Notice",
  ],
  architectural_document_name: [
    "ARC Guidelines",
    "ARC Guidelines / Form",
    "ARC Form",
    "ARC Form - Change",
    "ARC Form - Fencing",
    "ARC Form - Landscaping",
    "ARC Form - Painting",
    "ARC Form - Satellite Installation",
    "ARC Guidelines - Deck",
    "ARC Guidelines - Fence",
    "ARC Guidelines - Paint",
    "ARC Guidelines - Roofing",
  ],
  governing_document_name: [
    "Amendments",
    "Amendment to Bylaws",
    "Amendment to CC&Rs",
    "Articles of Incorporation",
    "Bylaws",
    "CC&Rs",
    "W9",
  ],
  leasing_document_name: [
    "Background Check Form",
    "Lease Addendum Form",
    "Move-in Checklist",
    "Parking Permit Form",
    "Pool Registration Form",
    "Tenant Registration Form",
    "Other",
  ],
  newsletterNotice_document_name: [
    "1 - January",
    "2 - February",
    "3 - March",
    "4 - April",
    "5 - May",
    "6 - June",
    "7 - July",
    "8 - August",
    "9 - September",
    "10 - October",
    "11 - November",
    "12 - December",
    "Annual Budget",
    "Annual Meeting",
    "Board Meeting",
    "Daily",
    "Fall",
    "Misc.",
    "Proposed Budget",
    "Spring",
    "Summer",
    "Weekly",
    "Winter",
  ],
  rulesAndPolicy_document_name: [
    "Access Form",
    "Collection and Fine Policy",
    "Gas Charcoal Grill Rules and Regulations",
    "Master insurance Policy",
    "Parking Rules",
    "Pet Policy",
    "Pool Rules",
    "Rental Policy",
    "Request for Community Property Permit",
    "Request to Inspect Association Records Form",
    "Rules and Regulations",
    "Violation Policy",
    "Other",
  ],

  financial_budget_document_name: [
    "FY16 Approved Budget",
    "FY19 Approved Budget",
    "FY19 Proposed Budget",
    "FY20 Approved Budget",
    "FY20 Proposed Budget",
    "FY21 Approved Budget",
    "FY21 Proposed Budget",
    "FY21 Payment Information",
    "Other",
  ],
  hoa_financial_budget_document_name: [
    "FY19 Approved Budget",
    "FY19 Proposed Budget",
    "FY20 Approved Budget",
    "FY20 Proposed Budget",
    "FY21 Approved Budget",
    "FY21 Proposed Budget",
    "FY21 Payment Information",
    "Other",
  ],
  deed_document_name: [
    "Act of Sale & Cancellation",
    "Amending Consent Judgment of Foreclosure Nunc Pro Tunc",
    "Assignment (Ground)",
    "Assignment of Lease & Bill of Sale",
    "Assignment of Leasehold Trustee’s",
    "Bargain & Sale",
    "Certificate of Foreclosure",
    "Certificate of Purchase of Real Estate",
    "Certificate of Title",
    "Confirmation Order (This is a in Lieu)",
    "Consent Judgment",
    "Court Appointed Trustee’s",
    "Decree of Foreclosure",
    "Deed in Lieu/ in Lieu of Foreclosure",
    "Deed of Assignment",
    "Deed Under Execution & Order of Sale",
    "Deed Under Power",
    "Economic Interest",
    "Fee Simple Trustee’s (Land and House)",
    "Foreclosure",
    "Judicial Sale",
    "Limited Warranty",
    "Massachusetts Foreclosure by Corp.",
    "Master Commissioner’s",
    "Master in Equity’s of Foreclosure",
    "Master’s Title",
    "Memorandum of Consent Judgment of Foreclosure",
    "Private Selling Officer's",
    "Process Verbal of Sheriff Sale",
    "Quit Claim",
    "Referee’s",
    "Referee’s in Foreclosure",
    "Sheriff’s Certificate of Sale & Foreclosure Record",
    "Sheriff’s",
    "Sheriff’s on Master Sale",
    "Sheriff’s on Mortgage and Foreclosure",
    "Sheriff’s on Mortgage Sale",
    "Sherrif's to Real Propery",
    "Special Master’s",
    "Statutory Warranty",
    "Substitute Trustee’s Assignment",
    "Substitute Trustee’s",
    "Trustee’s",
    "Trustee’s Upon Sale",
    "Verbal Process of Sale",
    "Warranty",
    "Warranty (Deed in Lieu)",
  ],
  lien_document_name: [
    "Association Assessment",
    "Child Support",
    "City Municipal",
    "Code Enforcement",
    "Creditor",
    "DOJ",
    "Inheritance Tax",
    "IRS",
    "Leasehold/Land Lease Ground Rent Assessment",
    "Mechanics",
    "Nuisance Violation",
    "Personal Property Tax",
    "Special Assessment",
    "State Tax",
    "Tax ",
    "UCC/Equipments",
    "US Judgment",
    "Water – Sewer – Sanitation – Utility",
  ],

  generalInfo: {
    addressFields: [
      // "physical_street_no_and_name",
      "physical_city",
      "physical_state",
    ],
    extraField: "physical_zip",
  },
  propertyManage: {
    addressFields: ["street_address", "city", "state"],
    extraField: "zip",
  },
  payment_generalInfo: {
    addressFields: ["payment_mailing_city", "payment_mailing_state"],
    extraField: "payment_mailing_zipcode",
  },
  leaseRestriction: {
    sectionTwoRestriction: [
      "at_lease_cap",
      "owner_reside_before_lease",
      "own_before_leasing_min",
      "senior_living_comunity",
      "lease_max_times_per_year",
      "lease_max",
    ],
    sectionThreeRestriction: [
      "lease_license_required",
      "rental_fee_required",
      "lease_approval_required",
      "association_app_fee_required",
      "copy_of_lease_required",
      "tenant_approval_required",
      "pet_allowed",
      "bg_check_required",
      "tenant_application_required",
      "association_move_in_checklist",
      "move_in_fee_required",
    ],
  },
  amenities_accesscode: {
    cp_access_controlled: "pool",
    cfc_access_controlled: "fitness",
    tc_access_controlled: "tennis",
    cch_access_controlled: "club_house",
  },
  access_for: {
    pool: "Pool Access",
    fitness: "Fitness Access",
    tennis: "Tennis Court Access",
    club_house: "Club House Access",
  },

  FY_Array: [
    19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
    38, 39, 40,
  ],
  area_type: ["County", "Zip Code"],
  municipalityInfo: {
    addressFields: ["city", "state", "zip"],
  },
  municipal_office: [
    "Administrative Office",
    "Building Department",
    "City Clerk",
    "Code Enforcement",
    "Community Development Department",
    "Finance Department",
    "Fire Department",
    "Housing Office",
    "Marshall’s Office",
    "Municipal Court",
    "Registration Department",
    "Tax Department",
  ],
  orderHistory: [
    "a_cos_orders",
    "a_fs_orders",
    "a_tos_orders",
    "o_cos_orders",
    "o_fs_orders",
    "o_tos_orders",
    "uo_orders",
  ],
  orderTableHeading: {
    a_cos_orders: "Abstract COS",
    a_fs_orders: "Abstract FS",
    a_tos_orders: "Abstract TOS",
    o_cos_orders: "Online COS",
    o_fs_orders: "Online FS",
    o_tos_orders: "Online TOS",
    uo_orders: "Update Order",
  },
  municipalityOrderHistory: ["muni_report_orders", "muni_update_orders"],
  municipalityorderTableHeading: {
    muni_report_orders: "Municipality Initial Search Order",
    muni_update_orders: "Municipality Update Search Order",
  },
  municipalPeriodicity: {
    "one-time": "One-Time",
    day: "Daily",
    week: "Weekly",
    "bi-week": "Bi-Weekly",
    month: "Monthly",
    quarter: "Quarterly",
    "semi-annual": "Semi-Annually",
    annual: "Annually",
    Voluntary: "Voluntary",
    "occupancy change": "Occupancy Change",
  },
  municipalOfficePocTitles: {
    "Code Officer": "Code Officer",
    "City Clerk": "City Clerk",
    Finance: "Finance",
    Inspector: "Inspector",
    "Police Officer": "Police Officer",
    "Registration Clerk": "Registration Clerk",
    "Tax Specialist": "Tax Specialist",
    "Utility Clerk": "Utility Clerk",
    other: "Other",
  },
  titleUserDashBoardRead: [
    "deed_coordinator",
    "deed_manager",
    "title_coordinator",
    "title_manager",
    "violations_coordinator",
    "violation_analyst",
    "municipal_manager",
    "municipal_coordinator",
  ],
  municipalUserDashBoardRead: [
    "municipality_coordinator",
    "municipality_manager",
  ],
  title_order_type: {
    "Online Current Owner Search": "o_cos",
    "Online Two owner Search": "o_tos",
    "Online Full Search": "o_fs",
    "Abstract Current Owner Search": "a_cos",
    "Abstract Two Owner Search": "a_tos",
    "Abstract Full Search": "a_fs",
    "Update Order": "uo",
  },

  municipal_order_type: {
    "Initial Search": "muni_report",
    "Update Search": "muni_update",
  },
  title_dashboard_scope: ["Title", "Deed", "Violation", "Lien", "Municipal"],
  requirementTypes: [
    "Business License Registration",
    // "Landlord Registration",
    "Vacant Property Registration",
    "Certificate of Occupancy",
    "Rental Registration",
    "Crime Free Housing",
    "Rental Taxes",
  ],
  lienTypes: [
    "Association Assessents",
    "Child Support",
    "City Municipal",
    "Code Enforcement",
    "Creditor",
    "DOJ",
    "Inheritance Tax",
    "IRS",
    "Leasehold/Land Lease Ground Rent Assessment",
    "Mechanics",
    "Nuisance Violation",
    "Personal Perporty Tax",
    "Special Assessment",
    "State Tax",
    "Tax",
    "UCC/Equipment",
    "US Judgement",
    "Water/Sewer/Sanitation/Utility",
  ],
  managementFees: [
    "Mgmt Co.Admin Fee",
    "Mgmt Co.Transfer Fee",
    "Statement of Account Fee",
    "Origination Fee",
  ],
  // codeViolationShortDescription: [
  //   "Failure To Register - Rental",
  //   "Failure To Register - Vehicle",
  //   "Fire Safety - Clearances to combustibles",
  //   "Full interior cleaning",
  //   "Infestation - Bees/Wasp",
  //   "Infestation - External Bugs",
  //   "Infestation - Internal Bugs",
  //   "Infestation - Rodents",
  //   "Install - Door Lock",
  //   "Install - Door Viewer",
  //   "Mechanical - Dryer Vent",
  //   "Remove/Replace EXT Wood",
  //   "Repair/replace toilet bolt cover",
  //   "Repair/replace transition strip",
  //   "Replace Broken Doors",
  //   "Replace Damaged Wall Plug",
  //   "Replace/Repair (Home Security System)",
  //   "Replace/repair garage door",
  //   "Replace/repair missing ceiling",
  //   "Replace/repair sliding door (exterior side)",
  //   "Stain (Bathroom Ceiling)",
  //   "Stain (Room Ceiling)",
  //   "Tile Installation",
  //   "Unmounted dishwasher",
  //   "Water Accumulation",
  //   "Wrong day watering",
  // ],
};
export default config;
